<template>
	<div class="row space-y-1 bg-white p-4">
		<div class="col-md-12" v-for="(item,key) in configList"
			 :key="key">
			<single-checkout-customize @removeItem="removeItem" :config-fields="configFields" :item="item"
			/>
		</div>
		<div class="grid grid-cols-1 md:grid-cols-5 lg:grid-cols-5 gap-3 w-full">
			<div class="col-span-2">
				<div class="form-group">
					<label for="inputCompanyName"
						   class="flex align-items-center mb-1 col-form-label font-light  whitespace-nowrap">{{
							$t('fieldTitle')
						}}<span class="help-tooltip cursor-pointer" :title="$t('fieldTitleHelpText')"></span></label>
					<div>
						<input data-cy='company-name' type="text" name="field_map" v-model="dataObj.field_title"
							   class="flex justify-between input-height-41px align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-gray-500 w-full">
					</div>
				</div>
			</div>
			<div class="col-span-2">
				<div class="form-group">
					<label for="fieldMap"
						   class="flex align-items-center mb-1 col-form-label font-light  whitespace-nowrap">{{
							$t('fieldMap')
						}}<span class="help-tooltip cursor-pointer" :title="$t('fieldMapHelpText')"></span></label>
					<div>
						<el-select v-model="dataObj.field_map" filterable
								   :placeholder="$t('selectAccountField')"
								   class="flex justify-between align-items-center space-x-4 focus:outline-none border rounded-md text-gray-500 w-full">
							<el-option
								v-for="(cc,key) in configFields"
								v-bind:key="key"
								:label="cc.value"
								:value="cc.key">
							</el-option>
						</el-select>
						<!--						<select v-model="dataObj.field_map"-->
						<!--								class="flex justify-between align-items-center bg-white space-x-4 focus:outline-none border px-3 py-2 rounded-md text-gray-500 w-full">-->
						<!--							<option value="">{{ $t('selectAccountField') }}</option>-->
						<!--							<option v-for="(item,key) in configFields" :key="key" :value="item.key"> {{ item.value }}-->
						<!--							</option>-->
						<!--						</select>-->
					</div>
				</div>
			</div>
			<div class="flex justify-between">

				<div class="form-group">
					<label for="inputCompanyName"
						   class="flex align-items-center mb-1 col-form-label font-light  whitespace-nowrap">{{
							$t('required')
						}}<span class="help-tooltip cursor-pointer" :title="$t('requiredHelpText')"></span></label>
					<div class="flex justify-center mt-3">
						<input data-cy='company-name' type="checkbox" :checked="dataObj.required"
							   v-model="dataObj.required"
							   class="checkbox-height">
					</div>
				</div>

			</div>
		</div>
		<div class="col-md-12 mt-3 mb-3">
                                <span class="flex justify-center">
                                    <button data-cy='company-btn' @click="updateAccountSettings" :disabled="updating"
											:style="GET_CUSTOMIZATION && GET_CUSTOMIZATION.button ? 'background:' + GET_CUSTOMIZATION.button : ''"
											:class="GET_CUSTOMIZATION && GET_CUSTOMIZATION.button ? '' : 'bg-green-500'"
											class="flex justify-center align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-white font-semibold w-11/12 md:w-1/3">
                   <span v-if="updating" class="flex align-items-center space-x-3">
                                            <svg class="animate-spin mr-2 h-5 w-5 text-white"
												 xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                            <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"
													stroke-width="4"></circle>
                                            <path class="opacity-75" fill="currentColor"
												  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                            </svg>
                                            {{ $t('updating') }}
                                        </span>
                                        <span v-else>{{ $t('save') }}</span>
                                    </button>
                                </span>
		</div>
	</div>
</template>

<script>
import {mapGetters} from 'vuex'
import SingleCheckoutCustomize from '../../views/portal/components/singleCheckoutCustomize'

export default {
	name: 'CheckoutCustomization',
	components: {SingleCheckoutCustomize},
	data: function () {
		return {
			updating: false,
			configList: [],
			configFields: [],
			dataObj: {
				field_title: '',
				field_map: '',
				required: false,
			}
		}
	},
	computed: {
		...mapGetters({
			GET_COMPANY_SETTING: 'portal/GET_COMPANY_SETTING',
			GET_SHOP_BILLING: 'portal/GET_SHOP_BILLING',
			GET_PRICE_LIST: 'portal/GET_PRICE_LIST',
			GET_COMPANY_FORM_HELP_TEXT: 'portal/GET_COMPANY_FORM_HELP_TEXT',
			GET_CUSTOMIZATION: 'portal/GET_CUSTOMIZATION'
		})
	},
	mounted() {
		this.getCompanyCheckoutConfig()
		this.getCompanyCheckoutConfigFields()
		this.$store.dispatch('portal/getAllEndCustomers')
	},
	methods: {
		updateAccountSettings: function () {
			this.updating = true
			let dataLoad = {
				rows: []
			}
			if (this.configList.length > 0) {
				let expectedStatus = true
				this.configList.map(item => {
					if (item.field_title === '' || item.field_map === '') {
						expectedStatus = false
					}
				})
				if (expectedStatus === false) {
					return this.$services.helpers.notification(this.$t('fieldRequired'), 'error', this)
				}
				if (this.dataObj.field_map !== '' && this.dataObj.field_title !== '') {
					const existMap = this.configList.filter(item => item.field_map === this.dataObj.field_map)
					if (existMap.length > 0) {
						this.updating = false
						return this.$services.helpers.notification(this.$t('accountingFieldAlreadyExist'), 'error', this)
					}
					dataLoad.rows.push(this.dataObj)
				}
				this.configList.map(item => {
					dataLoad.rows.push({
						field_map: item.field_map,
						field_title: item.field_title,
						required: item.required,
					})
				})

			} else {
				let expectedStatus = true
				Object.values(this.dataObj).map(item => {
					if (item === '') {
						expectedStatus = false
					}
				})
				if (expectedStatus === false) {
					this.updating = false
					return this.$services.helpers.notification(this.$t('fieldRequired'), 'error', this)
				}
				dataLoad.rows.push(this.dataObj)
			}
			this.$store.dispatch('portal/saveCompanyCheckoutConfiguration', dataLoad).then(resp => {
				this.$services.helpers.notification(this.$t('companyConfigurationSuccess'), 'success', this)
				this.getCompanyCheckoutConfig()
				this.updating = false
				this.dataObj = {
					field_title: '',
					field_map: '',
					required: false,
				}
			}).catch(err => {
				this.updating = false
				this.$services.helpers.notification(err.response.data.message, 'error', this)
			})
		},
		getCompanyCheckoutConfig: function () {
			this.configList = []
			this.$store.dispatch('portal/getCompanyCheckoutConfiguration').then(res => {
				this.configList = res.data.data
			}).catch(err => {
			})
		},
		getCompanyCheckoutConfigFields: function () {
			this.$store.dispatch('portal/getCompanyCheckoutConfigurationField').then(res => {
				this.configFields = res.data.data
			}).catch(err => {
			})
		},
		removeItem: function (obj) {
			const itemIndex = this.configList.findIndex(item => item.id === parseInt(obj.id))
			if (itemIndex > -1) {
				this.configList.splice(itemIndex, 1)
			}
		}
	}
}
</script>

<style scoped>

</style>
