<template>
	<div class="row space-y-1 bg-white p-4">
		<div class="col-md-6">
			<div class="form-group mt-1">
				<label
					class="label-w-input col-form-label font-light whitespace-nowrap ">
					<input data-cy='apply-max-credit' type="checkbox"
						   id="applyCustomerSpecificBasedMaxCredit"
						   v-model="billing.apply_specific_customer_max_credit"
						   class="checkbox-height  h-4 w-4">
					<span class="ml-2">{{ $t('applyCustomerSpecificBasedMaxCredit') }}<span
						class="help-tooltip cursor-pointer"
						v-bind:title="$t('applyCustomerSpecificBasedMaxCreditHelpText')"></span></span>
				</label>
			</div>
		</div>
		<div class="col-md-6">
			<div class="form-group">
				<label
					class="label-w-input col-form-label font-light  mb-1">
					<input data-cy='general-max-credit' type="checkbox"
						   @change="changeBooleanField('apply_general_max_credit', 'general_max_credit_amount')"
						   id="applyGeneralCreditforAllCustomers"
						   v-model="billing.apply_general_max_credit"
						   class="checkbox-height  h-4 w-4">
					<span class="ml-2">{{ $t('applyGeneralCreditforAllCustomers') }}<span
						class="help-tooltip cursor-pointer"
						v-bind:title="$t('applyGeneralCreditforAllCustomersHelpText')"></span></span>
				</label>
				<input data-cy='general-max-credit-amount' :placeholder="$t('amount')" type="number" min="0"
					   v-if="billing.apply_general_max_credit === true"
					   style="width:60% !important"
					   v-model="billing.general_max_credit_amount"
					   class="flex justify-between align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-gray-500 w-full">
			</div>
		</div>
		<div class="col-md-6">
			<div class="form-group">
				<label
					class="label-w-input col-form-label font-light whitespace-nowrap  mb-1">
					<input data-cy='apply-pricelist' type="checkbox" id="applyCustomerPricelist"
						   v-model="billing.apply_customer_pricelist"
						   class="checkbox-height  h-4 w-4">
					<span class="ml-2">{{ $t('applyCustomerPricelist') }}<span
						class="help-tooltip cursor-pointer"
						v-bind:title="$t('applyCustomerPricelistHelpText')"></span></span>
				</label>
			</div>
		</div>
		<div class="col-md-6">
			<div class="form-group">
				<label
					class="label-w-input col-form-label font-light whitespace-nowrap  mb-1">
					<input
						data-cy='apply-show_standard_price_additional_to_customer_specific_price'
						type="checkbox"
						id="show_standard_price_additional_to_customer_specific_price"
						v-model="billing.show_standard_price_additional_to_customer_specific_price"
						class="checkbox-height  h-4 w-4">
					<span class="ml-2">{{
							$t('show_standard_price_additional_to_customer_specific_price')
						}}<span
							class="help-tooltip cursor-pointer"
							v-bind:title="$t('show_standard_price_additional_to_customer_specific_price')"></span></span>
				</label>
			</div>
		</div>
		<div class="col-md-6">
			<div class="form-group">
				<label
					class="label-w-input col-form-label font-light whitespace-nowrap  mb-1">
					<input
						data-cy='sync_only_corporate_customers'
						type="checkbox"
						id="sync_only_corporate_customers"
						v-model="billing.sync_only_corporate_customers"
						class="checkbox-height  h-4 w-4">
					<span class="ml-2">{{
							$t('sync_only_corporate_customers')
						}}<span
							class="help-tooltip cursor-pointer"
							v-bind:title="$t('sync_only_corporate_customers')"></span></span>
				</label>
			</div>
		</div>
		<div class="col-md-12 mt-5 mb-4">
                                    <span class="flex justify-center">
                                        <button data-cy='setting-btn' @click="updateSettings" :disabled="updating"
												:style="GET_CUSTOMIZATION && GET_CUSTOMIZATION.button ? 'background:' + GET_CUSTOMIZATION.button : ''"
												:class="GET_CUSTOMIZATION && GET_CUSTOMIZATION.button ? '' : 'bg-green-500'"
												class="flex justify-center align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-white font-semibold w-11/12 md:w-1/3">
                                            <span v-if="updating" class="flex align-items-center space-x-3">
                                                <svg class="animate-spin mr-2 h-5 w-5 text-white"
													 xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                                <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"
														stroke-width="4"></circle>
                                                <path class="opacity-75" fill="currentColor"
													  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                                </svg>
                                                {{ $t('updating') }}
                                            </span>
                                            <span v-else>{{ $t('update') }}</span>
                                        </button>
                                    </span>
		</div>
	</div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
	name: "customerManagement",
	data: function () {
		return {
			billing: {
				apply_specific_customer_max_credit: '',
				apply_general_max_credit: '',
				general_max_credit_amount: '',
				apply_customer_pricelist: '',
				sync_only_corporate_customers: '',
				show_standard_price_additional_to_customer_specific_price: '',
			},
			croppedImage: null,
			updating: false
		}
	},
	methods: {
		changeBooleanField(s, e) {
			if (!this.billing[s]) {
				this.billing[e] = 0
			}
		},
		updateSettings() {
			if (parseInt(this.tab) === 1) {

				if (!this.billing.require_bank_id_for_for_orders_above_certain_amount) {
					this.billing.bankid_order_amount = 0
				}
			}
			if (!this.billing.apply_general_max_credit) {
				this.billing.general_max_credit_amount = 0
			} else {
				if (this.billing.general_max_credit_amount === 0) {
					this.$services.helpers.notification(this.$t('generalMaxCreditError'), 'error', this)
					return
				}
			}
			this.updating = true
			let payload = {
				apply_specific_customer_max_credit: this.billing.apply_specific_customer_max_credit,
				apply_general_max_credit: this.billing.apply_general_max_credit,
				general_max_credit_amount: this.billing.general_max_credit_amount,
				apply_customer_pricelist: this.billing.apply_customer_pricelist,
				sync_only_corporate_customers: this.billing.sync_only_corporate_customers,
				show_standard_price_additional_to_customer_specific_price: this.billing.show_standard_price_additional_to_customer_specific_price,
			}
			this.$store.dispatch('portal/updateCompanySettings', payload)
				.then(res => {
					this.updating = false
					this.$services.helpers.notification(this.$t('successCompanyupdated'), 'success', this)
				}).catch((err) => {
				this.updating = false
				window.Bus.$emit('output-error', err)
			})
		},
		getCompanyDetails() {
			this.$store.dispatch('portal/getCompanyDetails')
				.then(res => {
					this.billing = res.data
				})
				.catch(err => {
					if (err.response.status === 401) {
						this.$store.commit('auth/CLEAR_AUTH_USER', null)
						window.Bus.$emit('sign-out')
					}
				})
		},
	},
	computed: {
		...mapGetters({
			GET_CUSTOMIZATION: 'portal/GET_CUSTOMIZATION',
		})
	},
	mounted() {
		this.getCompanyDetails()
	}
}
</script>

<style scoped>

</style>
