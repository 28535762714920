<template>
	<div class="row space-y-1 bg-white p-4">
		<div class="grid grid-cols-2 md:grid-cols-2 lg:grid-cols-2 gap-3 w-full mb-2">

			<div class="col-span-1">
				<div class="form-group">
					<label
						class="flex align-items-center mb-1 col-form-label font-light  whitespace-nowrap">{{
							$t('emailHost')
						}}<span class="help-tooltip cursor-pointer" :title="''"></span></label>
					<div>
						<input data-cy='seller_frontend_domain' type="text" name="field_map"
							   v-model="dataObj.email_host"
							   class="flex justify-between input-height-41px align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-gray-500 w-full">
					</div>
				</div>
			</div>
			<div class="col-span-1">
				<div class="form-group">
					<label
						class="flex align-items-center mb-1 col-form-label font-light  whitespace-nowrap">{{
							$t('mail_server')
						}}<span class="help-tooltip cursor-pointer" :title="''"></span></label>
					<div>
						<!--						<select data-cy='mail_client_type'-->
						<!--								v-model="dataObj.mail_client_type"-->
						<!--								class="flex justify-between align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-gray-500 w-full">-->
						<!--							<option value="microsoft">{{ $t('microsoft') }}</option>-->
						<!--							<option value="default">{{ $t('other_mail_server') }}</option>-->
						<!--						</select>-->
						<el-select v-model="dataObj.mail_client_type" filterable
								   :placeholder="$t('select')"
								   class="flex justify-between align-items-center space-x-4 focus:outline-none border rounded-md text-gray-500 w-full">
							<el-option
								v-bind:key="1"
								:label="`${$t('microsoft')}`"
								:value="`microsoft`">
							</el-option>
							<el-option
								v-bind:key="2"
								:label="`${$t('other_mail_server')}`"
								:value="`default`">
							</el-option>
						</el-select>
					</div>
				</div>
			</div>
			<div class="col-span-1">
				<div class="form-group">
					<label
						class="flex align-items-center mb-1 col-form-label font-light  whitespace-nowrap">{{
							$t('emailUsername')
						}}<span class="help-tooltip cursor-pointer" :title="''"></span></label>
					<div>
						<input data-cy='email_username' type="text" name="field_map" v-model="dataObj.email_username"
							   class="flex justify-between input-height-41px align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-gray-500 w-full">

					</div>

				</div>
			</div>
			<div class="col-span-1">
				<div class="form-group">
					<label
						class="flex align-items-center mb-1 col-form-label font-light  whitespace-nowrap">{{
							$t('emailFrom')
						}}<span class="help-tooltip cursor-pointer" :title="''"></span></label>
					<div>
						<input data-cy='email_password' type="text" name="field_map" v-model="dataObj.from_email"
							   class="flex justify-between input-height-41px align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-gray-500 w-full">
					</div>
				</div>
			</div>
			<div class="col-span-1">
				<div class="form-group">
					<label
						class="flex align-items-center mb-1 col-form-label font-light  whitespace-nowrap">{{
							$t('emailPassword')
						}}<span class="help-tooltip cursor-pointer" :title="''"></span></label>
					<div class="position-relative">
						<span class="fa fa-eye position-absolute top-[14px] right-[10px] cursor-pointer" v-on:click.prevent="togglePassword"
							  v-if="showPassword === false"></span>
						<span class="fa fa-eye-slash position-absolute top-[14px] right-[10px] cursor-pointer" v-on:click.prevent="togglePassword"
							  v-else></span>
						<input data-cy='email_password' :type="showPassword === true ? 'password' :'text'"
							   name="field_map"
							   v-model="dataObj.email_password"
							   class="flex justify-between input-height-41px align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-gray-500 w-full">
					</div>
				</div>
			</div>
			<div class="col-span-1">
				<div class="form-group">
					<label
						class="flex align-items-center mb-1 col-form-label font-light  whitespace-nowrap">{{
							$t('emailPort')
						}}<span class="help-tooltip cursor-pointer" :title="''"></span></label>
					<div>
						<input data-cy='email_port' type="number" min="0" name="field_map" v-model="dataObj.email_port"
							   class="flex justify-between input-height-41px align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-gray-500 w-full">
					</div>
				</div>
			</div>
			<div class="col-span-1">
				<div class="form-group">
					<label
						class="flex align-items-center mb-1 col-form-label font-light  whitespace-nowrap">{{
							$t('connectionType')
						}}<span class="help-tooltip cursor-pointer"
								:title="$t('connectionTypeHelpText')"></span></label>
					<div class="grid grid-cols-4 md:grid-cols-4 lg:grid-cols-4">
						<label class="col-span-1">USE TLS<input data-cy='company-name' type="radio" value="tls"
																v-model="connectionType"
																class="checkbox-height ml-2"></label>
						<label class="col-span-1">
							USE SSL<input data-cy='company-name' type="radio" value="ssl"
										  v-model="connectionType"
										  class="checkbox-height ml-2">
						</label>
					</div>
				</div>
			</div>
		</div>
		<div class="col-md-12 mt-3 mb-3">
			<div class="lg:flex justify-center w-full">
				<button data-cy='company-btn' @click="updateEmailConfiguration" :disabled="updating"
						:style="GET_CUSTOMIZATION && GET_CUSTOMIZATION.button ? 'background:' + GET_CUSTOMIZATION.button : ''"
						:class="GET_CUSTOMIZATION && GET_CUSTOMIZATION.button ? '' : 'bg-green-500'"
						class="flex justify-center align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-white font-semibold w-11/12 md:w-1/3">
                   <span v-if="updating" class="flex align-items-center space-x-3">
                                            <svg class="animate-spin mr-2 h-5 w-5 text-white"
												 xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                            <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"
													stroke-width="4"></circle>
                                            <path class="opacity-75" fill="currentColor"
												  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                            </svg>
                                            {{ $t('updating') }}
                                        </span>
					<span v-else>{{ $t('save') }}</span>
				</button>

				<button data-cy='company-btn' v-on:click="getMicroSoftOauth"
						v-if="dataObj.mail_client_type === 'microsoft' && dataObj.is_microsoft_token === false"
						class="flex justify-center bg-blue-500 align-items-center space-x-4 focus:outline-none border px-3 py-2 lg:ml-3 rounded-md text-white font-semibold lg:w-1/4 w-11/12 lg:position-relative lg:right-9 ">
                   <span v-if="updating" class="flex align-items-center space-x-3">
                                            <svg class="animate-spin mr-2 h-5 w-5 text-white"
												 xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                            <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"
													stroke-width="4"></circle>
                                            <path class="opacity-75" fill="currentColor"
												  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                            </svg>
                                            {{ $t('authorize_microsoft_365') }}
                                        </span>
					<span v-else>{{ $t('authorize_microsoft_365') }}</span>
				</button>

			</div>

		</div>
	</div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
	name: "EmailSettingComponent",
	data: function () {
		return {
			updating: false,
			connectionType: 'tls',
			email_username: '',
			showPassword: true,
			dataObj: {
				email_host: '',
				email_username: '',
				email_password: '',
				email_port: '',
				mail_client_type: '',
				from_email: '',
				use_ssl: false,
				use_tls: true,
			}
		}
	},
	computed: {
		...mapGetters({
			GET_COMPANY_SETTING: 'portal/GET_COMPANY_SETTING',
			GET_SHOP_BILLING: 'portal/GET_SHOP_BILLING',
			GET_PRICE_LIST: 'portal/GET_PRICE_LIST',
			GET_COMPANY_FORM_HELP_TEXT: 'portal/GET_COMPANY_FORM_HELP_TEXT',
			GET_CUSTOMIZATION: 'portal/GET_CUSTOMIZATION'
		})
	},
	mounted() {
		this.getEmailConfiguration()
	},
	methods: {
		togglePassword: function () {
			this.showPassword = !this.showPassword
		},
		getEmailConfiguration() {
			this.$store.dispatch('portal/getEmailConfig').then(resp => {
				this.dataObj = resp.data.data
				this.email_username = resp.data.data.email_username
				if (this.dataObj.use_ssl === true) {
					this.connectionType = 'ssl'
				} else {
					this.connectionType = 'tls'
				}
			})
		},
		updateEmailConfiguration() {
			this.updating = true
			if (this.connectionType === 'tls') {
				this.dataObj.use_tls = true
				this.dataObj.use_ssl = false
			} else {
				this.dataObj.use_tls = false
				this.dataObj.use_ssl = true
			}
			this.$store.dispatch('portal/saveEmailConfig', {
				email_username: this.dataObj.email_username,
				email_password: this.dataObj.email_password,
				email_port: this.dataObj.email_port,
				email_host: this.dataObj.email_host,
				mail_client_type: this.dataObj.mail_client_type,
				from_email: this.dataObj.from_email,
				use_ssl: this.dataObj.use_ssl,
				use_tls: this.dataObj.use_tls,
			}).then(_ => {
				this.$services.helpers.notification(this.$t('companyEmailConfigurationSuccess'), 'success', this)
				this.updating = false
			}).catch(err => {
				this.updating = false
				this.$services.helpers.notification(err.response.data.message, 'error', this)
			})
		},
		getMicroSoftOauth: function () {
			this.$store.dispatch('portal/getMicrosoftUrl', {data: {'company_id': this.GET_COMPANY_SETTING.id}}).then(res => {
				window.open(res.data.url, '_blank')
			}).catch(err => {
				this.$services.helpers.notification(err.response.data.message, 'error', this)
			})
		}
	}
}
</script>

<style scoped>
.text-colored {
	color: #181c4c;
}
</style>
