<template>
	<div class="app-main__inner">
		<div class="container mb-3">
			<div class="row flex flex-col md:flex-row justify-between items-center align-items-center mb-3">
				<div data-cy='setting-header'>
					<div class="flex justify-between align-items-center space-x-3 mb-3" v-if="tab === 1">
						<span class="text-sm md:text-2xl font-semibold text-gray-900 dashboard-head-size">{{
								$t('customization')
							}}</span>
					</div>
					<div class="flex justify-between align-items-center space-x-3 mb-3" v-if="tab === 5">
						<span class="text-sm md:text-2xl font-semibold text-gray-900 dashboard-head-size">{{
								$t('checkoutCustomization')
							}}</span>
					</div>
					<div class="flex justify-between align-items-center space-x-3 mb-3" v-if="tab === 2">
						<span class="text-sm md:text-2xl font-semibold text-gray-900 dashboard-head-size">{{
								$t('settings')
							}}</span>
					</div>
					<div class="flex justify-between align-items-center space-x-3 mb-3" v-if="tab === 6">
						<span class="text-sm md:text-2xl font-semibold text-gray-900 dashboard-head-size">{{
								$t('emailSetting')
							}}</span>
					</div>
					<div class="flex justify-between align-items-center space-x-3 mb-3" v-if="tab === 3">
						<span class="text-sm md:text-2xl font-semibold text-gray-900 dashboard-head-size">{{
								$t('billing')
							}}</span>
					</div>
					<div class="flex justify-between align-items-center space-x-3 mb-3" v-if="tab === 4">
						<span class="text-sm md:text-2xl font-semibold text-gray-900 dashboard-head-size">{{
								$t('myAccount')
							}}</span>
					</div>
				</div>
				<div
					class="flex flex-col md:flex-row w-11/12 md:w-auto justify-between align-items-center uppercase border rounded-md bg-white text-xs md:text-xs">
					<span data-cy='tab1' @click="showTab(1)"
						  class="v-company-setting-2 text-xs h-8 w-full md:w-auto flex justify-center align-items-center cursor-pointer px-3 py-2 md:rounded-l-md"
						  :class="tab === 1 ? 'bg-blue-900 text-white' : 'bg-white text-gray-900'">{{
							$t('customization')
						}}</span>
					<span data-cy='tab1' @click="showTab(5)"
						  class="v-company-setting-2 text-xs h-8 w-full md:w-auto flex justify-center align-items-center cursor-pointer px-3 py-2"
						  :class="tab === 5 ? 'bg-blue-900 text-white' : 'bg-white text-gray-900'">{{
							$t('checkout')
						}}</span>
					<span data-cy='tab2' @click="showTab(2)"
						  class="v-company-setting-3 text-xs h-8 w-full md:w-auto flex justify-center align-items-center cursor-pointer px-3 py-2"
						  :class="tab === 2 ? 'bg-blue-900 text-white' : 'bg-white text-gray-900'">{{
							$t('settings')
						}}</span>
					<span data-cy='tab2' @click="showTab(6)" v-if="systemMode === 'whitelabel'"
						  class="v-company-setting-3 text-xs h-8 w-full md:w-auto flex justify-center align-items-center cursor-pointer px-3 py-2"
						  :class="tab === 6 ? 'bg-blue-900 text-white' : 'bg-white text-gray-900'">{{
							$t('emailSettings')
						}}</span>
					<span data-cy='tab3' @click="showTab(3)"
						  class="v-company-setting-4 text-xs h-8 w-full md:w-auto flex justify-center align-items-center cursor-pointer px-3 py-2"
						  :class="tab === 3 ? 'bg-blue-900 text-white' : 'bg-white text-gray-900'">{{
							$t('billing')
						}}</span>
					<span data-cy='tab4' @click="showTab(4)"
						  class="v-company-setting-5 text-xs h-8 w-full md:w-auto flex justify-center align-items-center cursor-pointer px-3 py-2 md:rounded-r-md whitespace-nowrap"
						  :class="tab === 4 ? 'bg-blue-900 text-white' : 'bg-white text-gray-900'">{{
							$t('myAccount')
						}}</span>
				</div>
			</div>
			<div class="row dm-sans-font">
				<div class="w-full dm-sans-font">
					<div class="">
						<!-- Grid 1: Customization -->
						<form @submit.prevent="updateShopownerCustomization" class="space-y-1 bg-white" v-if="tab === 1">
							<div class="row px-3">
								<div class="col-md-6 mt-2">
									<div class="form-group">
										<label for="inputCompanyName" class="flex align-items-center mb-1 col-form-label font-light  whitespace-nowrap">{{
											$t('inviteEmailSubject')
										}}<span class="help-tooltip cursor-pointer" :title="$t('inviteEmailSubjectHelpText')"></span></label>
										<div>
											<input data-cy='email-subject' v-model="billing.invite_email_subject" type="text" class="flex justify-between align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-gray-500 w-full">
										</div>
									</div>
								</div>
								<div class="col-md-6 mt-2">
									<div class="form-group">
										<label for="city" class="flex align-items-center col-form-label font-light whitespace-nowrap  mb-1">{{
											$t('menuColor')
										}}<span class="help-tooltip cursor-pointer" :title="$t('menuColorHelpText')"></span></label>
										<div class="flex space-x-3">
											<input data-cy='menu-color' v-model='billing.menu_color' type="text" class="flex justify-between align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-gray-500 w-11/12 md:w-1/2">
											<div>
												<el-color-picker v-model="billing.menu_color"></el-color-picker>
											</div>
										</div>
									</div>
								</div>
								<div class="col-md-6">
									<div class="form-group">
										<label for="inputCompanyNumber" class="flex align-items-center col-form-label font-light whitespace-nowrap  mb-1">{{
											$t('InviteEmailHeader')
										}}<span class="help-tooltip cursor-pointer" :title="$t('inviteEmailHeaderHelpText')"></span></label>
										<div>
											<input data-cy='email-header' v-model="billing.invite_email_header" type="text" class="flex justify-between align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-gray-500 w-full">
										</div>
									</div>
								</div>
								<div class="col-md-6">
									<div class="form-group">
										<label for="city" class="flex align-items-center col-form-label font-light whitespace-nowrap  mb-1">{{
											$t('buttonColor')
										}}<span class="help-tooltip cursor-pointer" :title="$t('buttonColorHelpText')"></span></label>
										<div class="flex space-x-3">
											<input data-cy='button-color' v-model='billing.button_color' type="text" class="flex justify-between align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-gray-500 w-11/12 md:w-1/2">
											<div>
												<el-color-picker v-model="billing.button_color"></el-color-picker>
											</div>
										</div>
									</div>
								</div>
								<div class="col-md-6">
									<div class="form-group">
										<label for="email" class="flex align-items-center col-form-label font-light whitespace-nowrap  mb-1">{{
											$t('linkColor')
										}}<span class="help-tooltip cursor-pointer" :title="$t('linkColorHelpText')"></span></label>
										<div class="flex space-x-3">
											<input data-cy='link-color' v-model='billing.link_color' type="text" class="flex justify-between align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-gray-500 w-11/12 md:w-1/2">
											<div>
												<el-color-picker v-model="billing.link_color"></el-color-picker>
											</div>
										</div>
									</div>
								</div>
								<div class="col-md-6">
									<div class="form-group">
										<label for="email" class="flex align-items-center col-form-label font-light whitespace-nowrap  mb-1">{{
											$t('buyer_sidebar_menu_color')
										}}<span class="help-tooltip cursor-pointer" :title="$t('buyer_sidebar_menu_colorHelpText')"></span></label>
										<div class="flex space-x-3">
											<input data-cy='link-color' v-model='billing.buyer_sidebar_menu_color' type="text" class="flex justify-between align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-gray-500 w-11/12 md:w-1/2">
											<div>
												<el-color-picker v-model="billing.buyer_sidebar_menu_color"></el-color-picker>
											</div>
										</div>
									</div>
								</div>
								<div class="col-md-6">
									<div class="form-group">
										<label for="inputCompanyAddress" class="flex align-items-center col-form-label font-light whitespace-nowrap  mb-1">{{
											$t('inviteEmailContent')
										}}<span class="help-tooltip cursor-pointer" :title="$t('inviteEmailContentHelpText')"></span></label>
										<div>
											<vue-editor class="h-custom-screen" @selection-change="onSelectChangeEditor" v-model="billing.invite_email_content"></vue-editor>
										</div>
									</div>
								</div>
								<div class="col-md-6">
									<div class="form-group">
										<label for="companyOrderConfirmationText" class="flex align-items-center col-form-label font-light whitespace-nowrap  mb-1">{{
											$t('order_confirmation_text')
										}}<span class="help-tooltip cursor-pointer" :title="$t('order_confirmation_text_help_text')"></span></label>
										<div>
											<vue-editor class="h-custom-screen" @selection-change="onSelectChangeEditor" v-model="billing.order_confirmation_text"></vue-editor>
										</div>
									</div>
								</div>
								
							</div>
							<div class="row px-3">
								<div class="col-md-6 mt-2">
									<div class="form-group">
										<label for="inputCompanyName" class="flex align-items-center mb-1 col-form-label font-light  whitespace-nowrap">{{
											$t('inviteEmailSubjectEn')
										}}<span class="help-tooltip cursor-pointer" :title="$t('inviteEmailSubjectHelpText')"></span></label>
										<div>
											<input data-cy='email-subject-en' v-model="billing.invite_email_subject_en" type="text" class="flex justify-between align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-gray-500 w-full">
										</div>
									</div>
								</div>
								<div class="col-md-6 mt-2">
									<div class="form-group">
										<label for="inputCompanyNumber" class="flex align-items-center col-form-label font-light whitespace-nowrap  mb-1">{{
											$t('InviteEmailHeaderEn')
										}}<span class="help-tooltip cursor-pointer" :title="$t('inviteEmailHeaderHelpText')"></span></label>
										<div>
											<input data-cy='email-header-en' v-model="billing.invite_email_header_en" type="text" class="flex justify-between align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-gray-500 w-full">
										</div>
									</div>
								</div>
								<div class="col-md-6">
									<div class="form-group">
										<label for="inputCompanyAddress" class="flex align-items-center col-form-label font-light whitespace-nowrap  mb-1">{{
											$t('inviteEmailContentEn')
										}}<span class="help-tooltip cursor-pointer" :title="$t('inviteEmailContentHelpText')"></span></label>
										<div>
											<vue-editor class="h-custom-screen" @selection-change="onSelectChangeEditor" v-model="billing.invite_email_content_en"></vue-editor>
										</div>
									</div>
								</div>
								<div class="col-md-6">
									<div class="form-group">
										<label for="companyOrderConfirmationText" class="flex align-items-center col-form-label font-light whitespace-nowrap  mb-1">{{
											$t('order_confirmation_text_en')
										}}<span class="help-tooltip cursor-pointer" :title="$t('order_confirmation_text_help_text')"></span></label>
										<div>
											<vue-editor class="h-custom-screen" @selection-change="onSelectChangeEditor" v-model="billing.order_confirmation_text_en"></vue-editor>
										</div>
									</div>
								</div>
								<div class="col-md-12 mt-5 mb-3">
                                <span class="flex justify-center">
                                    <button data-cy='customize-btn' :disabled="updating"
											:style="GET_CUSTOMIZATION && GET_CUSTOMIZATION.button ? 'background:' + GET_CUSTOMIZATION.button : ''"
											:class="GET_CUSTOMIZATION && GET_CUSTOMIZATION.button ? '' : 'bg-green-500'"
											class="flex justify-center align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-white font-semibold w-11/12 md:w-1/3">
                                        <span v-if="updating" class="flex align-items-center space-x-3">
                                            <svg class="animate-spin mr-2 h-5 w-5 text-white"
												 xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                            <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"
													stroke-width="4"></circle>
                                            <path class="opacity-75" fill="currentColor"
												  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                            </svg>
                                            {{ $t('updating') }}
                                        </span>
                                        <span v-else>{{ $t('update') }}</span>
                                    </button>
                                </span>
								</div>
							</div>
						</form>
						
						<div class="space-y-1" v-if="tab === 2">
							<company-setting-component/>
						</div>
						<div class="mt-2" v-if="tab === 3">
							<company-billing/>
						</div>
						<div class="row space-y-1 bg-white" v-if="tab === 4">
							<div class="col-md-6">
								<div class="form-group">
									<label for="inputCompanyName" class="flex align-items-center mb-1 col-form-label font-light  whitespace-nowrap">{{
										$t('companyName')
									}}<span class="help-tooltip cursor-pointer" :title="$t('CompanyNameHelpText')"></span></label>
									<div>
										<input data-cy='company-name' type="text" v-model="billing.name" class="flex justify-between align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-gray-500 w-full">
									</div>
								</div>
							</div>
							<div class="col-md-6 m-0">
								<div class="form-group">
									<label for="city" class="flex align-items-center col-form-label font-light whitespace-nowrap  mb-1">{{
										$t('city')
									}}<span class="help-tooltip cursor-pointer" :title="$t('cityHelpText')"></span></label>
									<div>
										<input data-cy='company-city' type="text" v-model="billing.city" class="flex justify-between align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-gray-500 w-full">
									</div>
								</div>
							</div>
							<div class="col-md-6">
								<div class="form-group">
									<label for="inputCompanyNumber" class="flex align-items-center col-form-label font-light whitespace-nowrap  mb-1">{{
										$t('companyNumber')
									}}<span class="help-tooltip cursor-pointer" :title="$t('companyNumberHelpText')"></span></label>
									<div>
										<input data-cy='company-number' type="text" v-model="billing.organization_number" class="flex justify-between align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-gray-500 w-full">
									</div>
								</div>
							</div>
							<div class="col-md-6">
								<div class="form-group">
									<label for="city" class="flex align-items-center col-form-label font-light whitespace-nowrap  mb-1">{{
										$t('phoneNumber')
									}}<span class="help-tooltip cursor-pointer" :title="$t('phoneNumberHelpText')"></span></label>
									<div>
										<input data-cy='company-phone' type="text" v-model="billing.phone_number" class="flex justify-between align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-gray-500 w-full">
									</div>
								</div>
							</div>
							<div class="col-md-6">
								<div class="form-group">
									<label for="inputCompanyAddress" class="flex align-items-center col-form-label font-light whitespace-nowrap  mb-1">{{
										$t('billingAddress')
									}}<span class="help-tooltip cursor-pointer" :title="$t('billingAddressHelpText')"></span></label>
									<div>
										<input data-cy='company-address' type="text" v-model="billing.address" class="flex justify-between align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-gray-500 w-full">
									</div>
								</div>
							</div>
							<div class="col-md-6">
								<div class="form-group">
									<label for="email" class="flex align-items-center col-form-label font-light whitespace-nowrap  mb-1">{{
										$t('email')
									}}<span class="help-tooltip cursor-pointer" :title="$t('emailHelpText')"></span></label>
									<div>
										<input data-cy='company-contact-email' type="email" v-model="billing.contact_email" class="flex justify-between align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-gray-500 w-full">
									</div>
								</div>
							</div>
							<div class="col-md-6">
								<div class="form-group">
									<label for="inputCompanyZipcode" class="flex align-items-center col-form-label font-light whitespace-nowrap  mb-1">{{
										$t('zipCode')
									}}<span class="help-tooltip cursor-pointer" :title="$t('zipCode')"></span></label>
									<div>
										<input data-cy='company-zipcode' type="text" v-model="billing.zip_code" class="flex justify-between align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-gray-500 w-full">
									</div>
								</div>
							</div>
							<div class="col-md-6">
								<div class="form-group">
									<label for="invoiceEmail" class="flex align-items-center col-form-label font-light whitespace-nowrap ">{{
										$t('invoiceEmail')
									}}<span class="help-tooltip cursor-pointer" :title="$t('invoiceEmailHelpText')"></span></label>
									<div>
										<input data-cy='company-invoice-email' type="email" v-model="billing.invoice_email" class="flex justify-between align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-gray-500 w-full">
									</div>
								</div>
							</div>
							<div class="col-md-6">
								<div class="form-group">
									<label for="inputCompanyZipcode" class="flex align-items-center col-form-label font-light whitespace-nowrap  mb-1">{{
										$t('shopDomain')
									}}<span class="help-tooltip cursor-pointer" :title="$t('shopDomain')"></span></label>
									<div>
										<input data-cy='company-shop-url' type="text" v-model="billing.shop_url" class="flex justify-between align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-gray-500 w-full">
									</div>
								</div>
							</div>
							<div class="col-md-6">
								<div class="form-group">
									<label for="invoiceEmail" class="flex align-items-center col-form-label font-light whitespace-nowrap ">{{
										$t('brandName')
									}}<span class="help-tooltip cursor-pointer" :title="$t('brandNameHelpText')"></span></label>
									<div>
										<input data-cy='company-brand' type="text" v-model="billing.brand_name" class="flex justify-between align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-gray-500 w-full">
									</div>
								</div>
							</div>
							<div class="col-md-6">
								<div class="form-group">
									<label for="invoiceEmail" class="flex align-items-center col-form-label font-light whitespace-nowrap ">Bankgiro<span class="help-tooltip cursor-pointer" :title="$t('bankGiroHelpText')"></span></label>
									<div>
										<input data-cy='company-bankgiro' type="text" v-model="billing.bankgiro" class="flex justify-between align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-gray-500 w-full">
									</div>
								</div>
							</div>
							<div class="col-md-6">
								<div class="form-group">
									<label for="invoiceEmail" class="flex align-items-center col-form-label font-light whitespace-nowrap ">Plusgiro<span class="help-tooltip cursor-pointer" :title="$t('plusgiroHelpText')"></span></label>
									<div>
										<input data-cy='company-plusgiro' type="text" v-model="billing.plusgiro" class="flex justify-between align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-gray-500 w-full">
									</div>
								</div>
							</div>
							<div class="col-md-6">
								<div class="form-group">
									<label for="invoiceEmail" class="flex align-items-center col-form-label font-light whitespace-nowrap ">IBAN<span class="help-tooltip cursor-pointer" :title="$t('IBANHelpText')"></span></label>
									<div>
										<input data-cy='company-iban' type="text" v-model="billing.iban" class="flex justify-between align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-gray-500 w-full">
									</div>
								</div>
							</div>
							<div class="col-md-6" v-if="systemMode === 'whitelabel'">
								<div class="form-group lg:mt-5">
									<div class="flex" v-if="loadingCompany === false">
										<div v-if="billing.is_token_valid === false" class="flex justify-between align-items-center border rounded-md text-xs md:text-xl px-2 bg-theme-blue">
                    						<span></span>
											<span data-cy='fortnox-connect' :class="{'cursor-pointer': processing === false}" v-on:click="onConnect" class="text-xs h-10 text-white flex justify-center align-items-center cursor-pointer px-3 py-2 rounded-l-md">
                        						{{ $t('connect') }} <svg v-if="processing === true" class="animate-spin ml-1 h-4 w-4 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                                <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                                                <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                        						</svg>
                    						</span>
										</div>
									</div>
								</div>
							</div>
							<div class="col-md-12 mt-3 mb-3">
                                <span class="flex justify-center">
                                    <button data-cy='company-btn' @click="updateAccountSettings" :disabled="updating"
											:style="GET_CUSTOMIZATION && GET_CUSTOMIZATION.button ? 'background:' + GET_CUSTOMIZATION.button : ''"
											:class="GET_CUSTOMIZATION && GET_CUSTOMIZATION.button ? '' : 'bg-green-500'"
											class="flex justify-center align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-white font-semibold w-11/12 md:w-1/3">
                                        <span v-if="updating" class="flex align-items-center space-x-3">
                                            <svg class="animate-spin mr-2 h-5 w-5 text-white"
												 xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                            <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"
													stroke-width="4"></circle>
                                            <path class="opacity-75" fill="currentColor"
												  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                            </svg>
                                            {{ $t('updating') }}
                                        </span>
                                        <span v-else>{{ $t('update') }}</span>
                                    </button>
                                </span>
							</div>
						</div>
						<div class="" v-if="tab === 5">
							<checkout-customization/>
						</div>
						<div class="" v-if="tab === 6">
							<email-setting-component/>
						</div>
					</div>
				</div>
			</div>
		</div>
		<Notification/>
	</div>
</template>

<script>
import {mapGetters} from 'vuex'
import bus from './../../bus'
import {VueEditor, Quill} from 'vue2-editor'
// import Paginate from 'vuejs-paginate'
// import Tiptap from "../../components/Tiptap";
import DownloadTableManager from './../../customComponent/DownloadTableManager'
import CheckoutCustomization from '@/views/portal/CheckoutCustomization'
import EmailSettingComponent from "./components/EmailSettingComponent";
import companySettingComponent from "./components/companySettingComponent";
import companyBilling from "@/views/portal/components/companyBilling"

export default {
	name: 'settings',
	components: {
		Notification: () => import('./../../customComponent/NotificationAlert'),
		DownloadTableManager,
		CheckoutCustomization,
		EmailSettingComponent,
		companySettingComponent,
		companyBilling,
		// Tiptap,
		VueEditor,
	},
	data() {
	return {
		tab: 1,
		systemMode: '',
		loadingCompany: false,
		PaymentType: null,
		company: {},
		billing: {
			name: '',
			organization_number: '',
			address: '',
			city: '',
			phone_number: '',
			post_invoice: false,
			shop_url: '',
			contact_email: '',
			id: '',
			zip_code: '',
			invoice_email: '',
			brand_name: '',
			invite_email_subject: '',
			invite_email_header: '',
			invite_email_content: '',
			order_confirmation_text: '',
			invite_email_subject_en: '',
			invite_email_header_en: '',
			invite_email_content_en: '',
			order_confirmation_text_en: '',
			use_accounting_customer_number_for_identifier: '',
			menu_color: '',
			button_color: '',
			link_color: '',
			buyer_sidebar_menu_color: '',
			price_list: {
				id: ''
			},
			bankgiro: '',
			plusgiro: '',
			iban: '',
			apply_specific_customer_max_credit: false,
			apply_general_max_credit: false,
			general_max_credit_amount: 0,
			apply_stock_threshold: false,
			stock_threshold_quantity: 0,
			require_bank_id_for_all_orders: false,
			display_store_to_all_buyers: false,
			require_bank_id_for_for_orders_above_certain_amount: 0,
			send_invoices_via_noxfinance: 0,
			bankid_order_amount: 0,
			minimum_order_amount: 0,
			hide_stock_quantity: 0,
			admin_email_address: '',
			send_order_notifications_to_admin: false,
			disable_customer_order_conf_emails: false,
			show_standard_price_additional_to_customer_specific_price: false,
			customer_invite_rule: '',
		},
		formPriceList: '',
		brandName: '',
		companyLogoObject: null,
		priceList: [],
		fullLogoList: [],
		halfLogoList: [],
		inviteRuleList: [
			{ key: 'one_time_in_week', name: 'Once in a week' },
			{ key: 'two_time_in_week', name: 'Once in a week' },
			{ key: 'three_time_in_week', name: 'Three time in a week' },
		],
		applyPriceList: true,
		croppedImage: null,
		fullLogoImage: null,
		fullLogo: null,
		halfLogoImage: null,
		updating: false,
		paymentCardModal: false,
		processingCard: false,
		processingInvoice: false,
		loading: false,
		customToolbar: [
			['bold', 'italic', 'underline'],
			[{ list: 'ordered' }, { list: 'bullet' }],
			['code-block']
		],
	}
},

	computed: {
		...mapGetters({
			GET_COMPANY_SETTING: 'portal/GET_COMPANY_SETTING',
			GET_SHOP_BILLING: 'portal/GET_SHOP_BILLING',
			GET_PRICE_LIST: 'portal/GET_PRICE_LIST',
			GET_COMPANY_FORM_HELP_TEXT: 'portal/GET_COMPANY_FORM_HELP_TEXT',
			GET_CUSTOMIZATION: 'portal/GET_CUSTOMIZATION',
			GET_COMPANY_SUBSCRIPTION_SETTING: 'portal/GET_COMPANY_SUBSCRIPTION_SETTING',
			GET_USER_SUBSCRIPTION: 'portal/GET_USER_SUBSCRIPTION'
		})
	},
	mounted() {

		this.getCompanyDetails()
		this.getFormFieldHelpText()
		// this.getShopBilling()
		this.getPriceList()
		this.getUserSubscription()
		setTimeout(function () {
			window.Bus.$emit('start-company-tour')
		}, 200)
		const self = this
	

		bus.$on('logo-image', function (e) {
			self.companyLogoObject = e.logo
			self.croppedImage = e.base64
		})
	},
	beforeMount() {
		this.systemMode = process.env.VUE_APP_SYSTEM_MODE
	},
	methods: {
		onSelectChangeEditor: function (range) {

		},
		onConnect: function () {
			let payload = {'company_id': this.billing.id}
			this.processing = true
			this.$store.dispatch('auth/getAuthPermissionUrl', {'data': payload}).then(resp => {
				this.processing = false
				window.open(resp.data.url, '_blank')
			}).catch(err => {
				this.processing = false
				window.Bus.$emit('output-error', err)
			})
		},
		changeBooleanField(s, e) {
			if (!this.billing[s]) {
				this.billing[e] = 0
			}
		},
		getCompanyDetails() {
			this.$store.dispatch('portal/getCompanyDetails')
				.then(res => {
					this.billing = this.GET_COMPANY_SETTING
				})
				.catch(err => {
					if (err.response.status === 401) {
						this.$store.commit('auth/CLEAR_AUTH_USER', null)
						window.Bus.$emit('sign-out')
					}
				})
		},
		getShopBilling() {
			this.$store.dispatch('portal/getShopBillingInfo')
				.then(_ => {

				})
				.catch(_ => {

				})
		},
		getPriceList() {
			this.$store.dispatch('portal/getPriceList')
		},
		openLogoModal() {
			// bus.$emit('open-modal')
			this.$store.commit('utility/OPEN_PHOTO_MODAL')
		},
		updateShopownerCustomization() {
		this.updating = true;
			const payload = {
				invite_email_header: this.billing.invite_email_header,
				invite_email_subject: this.billing.invite_email_subject,
				invite_email_content: this.billing.invite_email_content,
				order_confirmation_text: this.billing.order_confirmation_text,
				invite_email_header_en: this.billing.invite_email_header_en,
				invite_email_subject_en: this.billing.invite_email_subject_en,
				invite_email_content_en: this.billing.invite_email_content_en,
				order_confirmation_text_en: this.billing.order_confirmation_text_en,
				buyer_sidebar_menu_color: this.billing.buyer_sidebar_menu_color,
				menu_color: this.billing.menu_color,
				button_color: this.billing.button_color,
				link_color: this.billing.link_color,
			};
	
			this.$store.dispatch('portal/updateCompanySettings', payload)
				.then(res => {
					if (res.data) {
						this.storeCustomization(res.data)
						this.updating = false
						this.$services.helpers.notification(this.$t('successCompanyupdated'), 'success', this)
					}
				}).catch((err) => {
				this.updating = false
				if (err.response.status === 401) {
					this.$store.commit('auth/CLEAR_AUTH_USER', null)
					window.Bus.$emit('sign-out')
				}
			})
			// }
		},
		storeCustomization(data) {

			const payload = {
				menu: data.menu_color,
				link: data.link_color,
				button: data.button_color
			}
	
			this.$store.commit('portal/UPDATE_CUSTOMIZATION', payload)
		},
		updateSettings() {
	
			if (parseInt(this.tab) === 1) {
		
				if (!this.billing.apply_general_max_credit) {
					this.billing.general_max_credit_amount = 0
				}

				// if (!this.billing.apply_stock_threshold) {
				//     this.billing.stock_threshold_quantity = 0
				// }

				if (!this.billing.require_bank_id_for_for_orders_above_certain_amount) {
					this.billing.bankid_order_amount = 0
				}
			}
			if (this.billing.brand_name === '' || this.billing.store_description === '') {
				this.$services.helpers.notification(this.$t('fieldRequired'), 'error', this)
				return false
			} else {
				this.updating = true
				const payload = {
					brand_name: this.billing.brand_name,
					store_description: this.billing.store_description,
					post_invoice: this.billing.post_invoice,
					apply_customer_pricelist: this.billing.apply_customer_pricelist,
					apply_specific_customer_max_credit: this.billing.apply_specific_customer_max_credit,
					apply_general_max_credit: this.billing.apply_general_max_credit,
					general_max_credit_amount: this.billing.general_max_credit_amount,
					apply_stock_threshold: this.billing.apply_stock_threshold,
					use_accounting_customer_number_for_identifier: this.billing.use_accounting_customer_number_for_identifier,
					// stock_threshold_quantity : this.billing.stock_threshold_quantity,
					require_bank_id_for_all_orders: this.billing.require_bank_id_for_all_orders,
					require_bank_id_for_for_orders_above_certain_amount: this.billing.require_bank_id_for_for_orders_above_certain_amount,
					bankid_order_amount: this.billing.bankid_order_amount,
					threshold_product_visibility: this.billing.threshold_product_visibility,
					send_invoices_via_noxfinance: this.billing.send_invoices_via_noxfinance,
					display_store_to_all_buyers: this.billing.display_store_to_all_buyers,
					minimum_order_amount: this.billing.minimum_order_amount,
					hide_stock_quantity: this.billing.hide_stock_quantity,
					send_order_notifications_to_admin: this.billing.send_order_notifications_to_admin,
					admin_email_address: this.billing.admin_email_address,
					disable_customer_order_conf_emails: this.billing.disable_customer_order_conf_emails,
					customer_invite_rule: this.billing.customer_invite_rule,
					show_standard_price_additional_to_customer_specific_price: this.billing.show_standard_price_additional_to_customer_specific_price,
				}

				this.$store.dispatch('portal/updateCompanySettings', payload)
					.then(res => {
						if (this.companyLogoObject) {
							this.uploadCompanyLogo()
						} else {
							this.updating = false
							this.$services.helpers.notification(this.$t('successCompanyupdated'), 'success', this)
						}
						if (this.fullLogoList.length > 0 || this.halfLogoList.length > 0) {
							this.uploadCompanyImages()

						}
					
					}).catch((err) => {
					this.updating = false
					if (err.response.status === 401) {
						this.$store.commit('auth/CLEAR_AUTH_USER', null)
						window.Bus.$emit('sign-out')
					}
					this.$services.helpers.notification(err.response.data, 'error', this)
				})
			}
		},
		updateAccountSettings() {
			// if (this.billing.name === '' || this.billing.address === '' || this.billing.address === null ||
			// 	this.billing.organization_number === '' || this.billing.city === '' || this.billing.phone_number === '' ||
			// 	this.billing.phone_number === null || this.billing.zip_code === '' || this.billing.invoice_email === '' ||
			// 	this.billing.invoice_email === null || this.billing.contact_email === '' || this.billing.contact_email === null ||
			// 	this.billing.shop_url === '') { // this.billing.price_list === '' || this.billing.price_list === null ||
			// 	this.$services.helpers.notification(this.$t('fieldRequired'), 'error', this)
			// 	return false
			// }

			if (this.billing.organization_number) {
				if (this.$services.helpers.validateCompanyNumber(this.billing.organization_number)) {
					this.$services.helpers.notification(this.$t('invalidCom'), 'error', this)
					return false
				}
			}

			this.updating = true
			const payload = {
				name: this.billing.name,
				address: this.billing.address,
				phone_number: this.billing.phone_number,
				city: this.billing.city,
				zip_code: this.billing.zip_code,
				organization_number: this.billing.organization_number,
				invoice_email: this.billing.invoice_email,
				contact_email: this.billing.contact_email,
				shop_url: this.billing.shop_url,
				// price_list : { id  : this.billing.price_list.id },
				bankgiro: this.billing.bankgiro,
				plusgiro: this.billing.plusgiro,
				iban: this.billing.iban,
			}
			const form = {}
			for (const [key, value] of Object.entries(payload)) {
				if (value !== '' && value !== null && value !== undefined) {
					form[key] = value
				}
			}

			this.$store.dispatch('portal/updateCompanySettings', form)
				.then(res => {
					if (this.companyLogoObject) {
						this.uploadCompanyLogo()
					} else {
						this.updating = false
						this.$services.helpers.notification(this.$t('successCompanyupdated'), 'success', this)
					}
				}).catch((err) => {
				this.updating = false
				if (err.response.status === 401) {
					this.$store.commit('auth/CLEAR_AUTH_USER', null)
					window.Bus.$emit('sign-out')
				}
			})
		},
		uploadCompanyLogo() {
			const formData = new FormData()
			formData.append('file', this.companyLogoObject)

			this.$store.dispatch('portal/uploadCompanyLogo', formData)
				.then(res => {
					this.updating = false
					bus.$emit('update-company-logo')
					this.$services.helpers.notification(this.$t('successCompanyupdated'), 'success', this)
				}).catch((err) => {
				this.updating = false
				if (err.response.status === 401) {
					this.$store.commit('auth/CLEAR_AUTH_USER', null)
					window.Bus.$emit('sign-out')
				}
			})
		},
		uploadCompanyImages() {
			const formData = new FormData()
			if (this.fullLogoList.length > 0) {
				formData.append('full_logo', this.fullLogoList[0])
			}
			if (this.halfLogoList.length > 0) {
				formData.append('half_logo', this.halfLogoList[0])
			}
			this.$store.dispatch('portal/uploadCompanyImages', formData)
				.then(res => {
					this.updating = false
					window.Bus.$emit('update-company-images')
					this.fullLogoList = []
					this.halfLogoList = []
					if (this.fullLogoList.length > 0) {
						setTimeout(function () {
							document.getElementsByClassName('el-icon-close')[0].click()
						}, 500)
					}
					if (this.halfLogoList.length > 0) {
						setTimeout(function () {
							document.getElementsByClassName('upload-half-demo')[0].getElementsByClassName('el-icon-close')[0].click()
						}, 500)
					}

					// this.$services.helpers.notification(this.$t('successCompanyupdated'), 'success', this)
				}).catch((err) => {
				this.updating = false
				if (err.response.status === 401) {
					this.$store.commit('auth/CLEAR_AUTH_USER', null)
					window.Bus.$emit('sign-out')
				}
			})
		},
		getFormFieldHelpText() {
			// this.$store.dispatch('portal/getFormFieldHelpText')
		},
		sortCompanyNameAscending() {
			this.$store.commit('portal/SORT_BILLING_NAME_ASC')
		},
		sortCompanyNameDescending() {
			this.$store.commit('portal/SORT_BILLING_NAME_DSC')
		},
		sortDateAscending() {
			this.$store.commit('portal/SORT_BILLING_DATE_ASC')
		},
		sortDateDescending() {
			this.$store.commit('portal/SORT_BILLING_DATE_DESC')
		},
		sortDueDateAscending() {
			this.$store.commit('portal/SORT_BILLING_DUE_DATE_ASC')
		},
		sortDueDateDescending() {
			this.$store.commit('portal/SORT_BILLING_DUE_DATE_DESC')
		},
		sortPriceAscending() {
			this.$store.commit('portal/SORT_BILLING_PRICE_ASC')
		},
		sortPriceDescending() {
			this.$store.commit('portal/SORT_BILLING_PRICE_DESC')
		},
		showTab(num) {
			this.tab = num
		},
		FullImageOnchange(files) {
			const fileType = ['png', 'jpg', 'jpeg']
			const file = files.raw.type.split('/')[1]
			const limit = (1 - this.fullLogoList.length)
			const self = this
			if (limit > 0) {
				if (fileType.includes(file)) {
					const img = new Image()
					img.src = window.URL.createObjectURL(files.raw)
					img.onload = () => {
						if (img.width <= 200 && img.height <= 200) {
							self.fullLogoList.push(files.raw)
							const reader = new FileReader()
							reader.onload = function (e) {
								self.fullLogoImage = e.target.result
							}
							reader.readAsDataURL(files.raw)
						} else {
							this.fullLogoList.pop()
							setTimeout(function () {
								document.getElementsByClassName('el-icon-close')[0].click()
							}, 500)
						}
					}
				} else {
					return this.$services.helpers.notification(this.$t('invalidFormat'), 'error', this)
				}
			} else {
				if (this.fullLogoList.length > 0) {
					this.fullLogoList.pop()
				}

			}
		},
		halfLogoImageOnchange(files) {
			const fileType = ['png', 'jpg', 'jpeg']
			const file = files.raw.type.split('/')[1]
			const limit = (1 - this.fullLogoList.length)
			const self = this
			if (limit > 0) {
				if (fileType.includes(file)) {
					const img = new Image()
					img.src = window.URL.createObjectURL(files.raw)
					img.onload = () => {
						if (img.width <= 100 && img.height <= 100) {
							self.halfLogoList.push(files.raw)
							const reader = new FileReader()
							reader.onload = function (e) {
								self.halfLogoImage = e.target.result
							}
							reader.readAsDataURL(files.raw)
						} else {
							this.halfLogoList.pop()
							setTimeout(function () {
								document.getElementsByClassName('upload-half-demo')[0].getElementsByClassName('el-icon-close')[0].click()
							}, 500)
						}
					}
				} else {
					return this.$services.helpers.notification(this.$t('invalidFormat'), 'error', this)
				}
			} else {
				if (this.halfLogoList.length > 0) {
					this.halfLogoList.pop()
				}

			}
		},
		handleRemove(file) {
			this.fullLogoList.pop()
		},
		handleHalfRemove(file) {
			this.halfLogoList.pop()
		},
		getBase64File(file) {
			return new Promise((resolve, reject) => {
				var reader = new FileReader()
				reader.onload = e => {
					resolve(e.target.result)
				}
				reader.readAsDataURL(file)
			})
		},
		getUserSubscription() {
			this.$store.dispatch('portal/getUserSubscription')
		}
	}
}
</script>

<style lang="scss" scoped>
@import "./../../assets/variables.scss";

.custom-btn-pay {
	background-color: #F167CA !important;
	border-color: #F167CA !important;
	font-weight: 800;
}

.custom-card-section {
	box-shadow: 0 0.46875rem 2.1875rem rgba(4, 9, 20, 0.03), 0 0.9375rem 1.40625rem rgba(4, 9, 20, 0.03), 0 0.25rem 0.53125rem rgba(4, 9, 20, 0.05), 0 0.125rem 0.1875rem rgba(4, 9, 20, 0.03);
	border-width: 0;
	transition: all .2s;
	background-color: #fff;
	background-clip: border-box;
	border: 1px solid rgba(26, 54, 126, 0.125);
}

.custom-save-btn {
	background-color: #2CDCA7 !important;
	border-color: #2CDCA7 !important;
	font-weight: 800;
}

.override-form-field {
	border: 0;
	border-bottom: 2px solid rgb(64, 138, 235);
	box-shadow: none;
	background-color: #fff !important;
	border-radius: unset !important;
}

.col-form-label {
	padding-top: 1rem !important;
	// font-size: 14px !important;
}

.form-group {
	margin-bottom: 15px !important;
}

.form-input {
	font-size: 0.8rem !important;
	padding: 0.2rem 0.75rem !important;
}

.col-form-label {
	padding-bottom: unset !important;
	padding-top: 0.3rem !important;
}

.app-main .app-main__inner {
	padding-top: 20px !important;
}

.table td {
	padding: 0.7rem 0.55rem !important;
}

.form-control, .form-select {
	border: 1px solid $form-border !important;
	height: unset !important;
	padding: .175rem .75rem !important;
}

.form-control:focus {
	box-shadow: unset !important;
	border: 1px solid $theme-primary-color !important;
}

.btn-border-all {
	border: 1px solid $theme-secondary-color !important;
	color: $theme-secondary-color !important;
}

.settings-page-contain {
	background-color: #fff;
	padding: 20px 30px 30px;
	border-radius: 4px;
	box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.05);
}

label.label-w-input {
	display: flex;
	margin-bottom: 5px;
	align-items: center;
}

label.label-w-input input {
	margin-right: 10px;
}

.el-color-picker {
	.el-color-picker__trigger {
		height: 31px !important;
		width: 31px !important;
	}
}

.table tbody tr > td {
	font-size: 13px !important;
	color: #6F6F6F !important;
	padding: 0.6rem 0.55rem !important;
}

.bg-theme-blue {
	background: #303DB7;
	border: 0.5px solid #303DB7;
}
</style>
