<template>
	<div>
		<div data-cy='billing-table' class="table-responsive rounded-lg bg-white border">
			<table v-loading='loading' style="width: 100%;" id="exampledestroy"
				   class="table table-hover table-bordered">
				<thead>
				<tr class="align-items-center">
					<th class="text-xs">
						<div class="d-flex justify-content-between align-items-center">
							{{ $t('companyName') }}<span class="flex flex-col"><i
							@click="sortCompanyNameAscending"
							class="lnr-chevron-up cursor-pointer small"></i><i
							@click="sortCompanyNameDescending"
							class="lnr-chevron-down cursor-pointer small"></i></span></div>
					</th>
					<th class="text-xs">
						<div class="d-flex justify-content-between align-items-center">
							{{ $t('amountDue') }}<span class="flex flex-col"><i
							@click="sortPriceAscending"
							class="lnr-chevron-up cursor-pointer small"></i><i
							@click="sortPriceDescending"
							class="lnr-chevron-down cursor-pointer small"></i></span></div>
					</th>
					<th class="text-xs">
						<div class="d-flex justify-content-between align-items-center">{{
								$t('date')
							}}<span class="flex flex-col"><i @click="sortDateAscending"
															 class="lnr-chevron-up cursor-pointer small"></i><i
								@click="sortDateDescending"
								class="lnr-chevron-down cursor-pointer small"></i></span></div>
					</th>
					<th class="text-xs">
						<div class="d-flex justify-content-between align-items-center">
							{{ $t('dueDate') }}<span class="flex flex-col"><i
							@click="sortDueDateAscending"
							class="lnr-chevron-up cursor-pointer small"></i><i
							@click="sortDueDateDescending"
							class="lnr-chevron-down cursor-pointer small"></i></span></div>
					</th>
					<th class="text-xs">
						<div class="flex justify-center align-items-center">{{
								$t('status')
							}}<span></span></div>
					</th>
					<th class="text-xs">
						<div class="flex justify-center align-items-center">{{
								$t('file')
							}}<span></span></div>
					</th>
				</tr>
				</thead>
				<tbody>
				<tr v-for="billing in GET_SHOP_BILLING.results" :key="billing.id">
					<td class="text-xs font-light">{{ billing.customer_name }}</td>
					<td class="text-xs font-light">{{ billing.total }} SEK</td>
					<td class="text-xs font-light">{{ billing.invoice_date }}</td>
					<td class="text-xs font-light">{{ billing.due_date }}</td>
					<td class="text-xs font-light">
											<span v-if="billing.balance === 0"
												  class="px-3 py-1 bg-green-500 text-white rounded-sm">{{
													$t('paid')
												}}</span>
						<span v-else class="px-3 py-1 bg-red-600 text-white rounded-sm">{{
								$t('unPaid')
							}}</span>
					</td>
					<td>
						<DownloadTableManager value='billing' :data='billing'/>
					</td>
				</tr>
				</tbody>
			</table>
		</div>
	</div>
</template>

<script>
import {mapGetters} from "vuex"
// import DownloadTableManager from '@/views/customComponent/DownloadTableManager'
import DownloadTableManager from './../../../customComponent/DownloadTableManager'

export default {
	name: "companyBilling",
	components: {
		DownloadTableManager
	},
	data: function () {
		return {
			loading: false
		}
	},
	computed: {
		...mapGetters({
			GET_SHOP_BILLING: 'portal/GET_SHOP_BILLING'
		})
	},
	mounted() {
		this.getShopBilling()
	},
	methods: {
		getShopBilling() {
			this.$store.dispatch('portal/getShopBillingInfo')
				.then(_ => {

				})
				.catch(_ => {

				})
		},
		sortCompanyNameAscending() {
			this.$store.commit('portal/SORT_BILLING_NAME_ASC')
		},
		sortCompanyNameDescending() {
			this.$store.commit('portal/SORT_BILLING_NAME_DSC')
		},
		sortDateAscending() {
			this.$store.commit('portal/SORT_BILLING_DATE_ASC')
		},
		sortDateDescending() {
			this.$store.commit('portal/SORT_BILLING_DATE_DESC')
		},
		sortDueDateAscending() {
			this.$store.commit('portal/SORT_BILLING_DUE_DATE_ASC')
		},
		sortDueDateDescending() {
			this.$store.commit('portal/SORT_BILLING_DUE_DATE_DESC')
		},
		sortPriceAscending() {
			this.$store.commit('portal/SORT_BILLING_PRICE_ASC')
		},
		sortPriceDescending() {
			this.$store.commit('portal/SORT_BILLING_PRICE_DESC')
		},
	}
}
</script>

<style scoped>

</style>
