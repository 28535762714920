<template>
	<div>
		<div class="grid grid-cols-1 md:grid-cols-5 lg:grid-cols-5 gap-3 w-full mb-2">
			<div class="col-span-2">
				<div class="form-group">
					<label for="inputCompanyName"
						   class="flex align-items-center mb-1 col-form-label font-light  whitespace-nowrap">{{
							$t('fieldTitle')
						}}<span class="help-tooltip cursor-pointer" :title="$t('fieldTitleHelpText')"></span></label>
					<div>
						<input data-cy='company-name' type="text" name="field_map" v-model="dataObj.field_title"
							   class="flex justify-between input-height-41px align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-gray-500 w-full">
					</div>
				</div>
			</div>
			<div class="col-span-2">
				<div class="form-group">
					<label for="fieldMap"
						   class="flex align-items-center mb-1 col-form-label font-light  whitespace-nowrap">{{
							$t('fieldMap')
						}}<span class="help-tooltip cursor-pointer" :title="$t('fieldMapHelpText')"></span></label>
					<div>
<!--						<select v-model="dataObj.field_map"-->
<!--								class="flex justify-between align-items-center bg-white space-x-4 focus:outline-none border px-3 py-2 rounded-md text-gray-500 w-full">-->
<!--							<option v-for="(item,key) in configFields" :key="key" :value="item.key"> {{ item.value }}-->
<!--							</option>-->
<!--						</select>-->
						<el-select v-model="dataObj.field_map" filterable
								   :placeholder="$t('selectAccountField')"
								   class="flex justify-between align-items-center space-x-4 focus:outline-none border rounded-md text-gray-500 w-full">
							<el-option
								v-for="(cc,key) in configFields"
								v-bind:key="key"
								:label="cc.value"
								:value="cc.key">
							</el-option>
						</el-select>
					</div>
				</div>
			</div>
			<div class="flex justify-between">
				<div class="form-group">
					<label for="inputCompanyName"
						   class="flex align-items-center mb-1 col-form-label font-light  whitespace-nowrap">{{
							$t('applyTo')
						}}<span class="help-tooltip cursor-pointer" :title="$t('applyToCustomerText')"></span></label>
					<span class="position-relative">
					<span class="w-full badge input-height-41px text-black-50 cursor-pointer bg-white border"
						  @click="fireOpenRelevant"
						  :class="{'bg-green-custom': relevantProduct === 'true','bg-red-500': relevantProduct === 'false','bg-blue-500': relevantProduct === 'select'}">
						<span class="relevant" v-if="dataObj.is_customer_specific === false">{{
								$t('allCustomer')
							}} </span>
						<span class="relevant" v-else>{{ $t('customerSpecific') }} </span>
					</span>
					<div class="absolute w-60 bg-white rounded border shadow-lg md:-ml-20" style="z-index:9999"
						 v-if="relevantOpen === true">
							 <div>
                    <div class="bg-white border-b" v-on:click="onChangeSpecific('all')">
                        <label class="inline-flex align-items-center item-center mt-1 ml-3">
                            <span class="ml-2 cursor-pointer text-xs">{{ $t('allCustomer') }} </span>
                        </label>
                    </div></div>
                    <div class="bg-white border-b" v-on:click="onChangeSpecific('customer_specific')">
                        <label class="inline-flex align-items-center item-center mt-1 ml-3">
                            <span class="ml-2 cursor-pointer text-xs">{{ $t('customerSpecific') }} </span>
                        </label>
                    </div>
				</div>
				</span>
				</div>
				<div class="form-group">
					<label for="inputCompanyName"
						   class="flex align-items-center mb-1 col-form-label font-light  whitespace-nowrap">{{
							$t('required')
						}}<span class="help-tooltip cursor-pointer" :title="$t('requiredHelpText')"></span></label>
					<div class="flex justify-center mt-3">
						<input data-cy='company-name' type="checkbox" :checked="dataObj.required"
							   v-model="dataObj.required"
							   class="checkbox-height">
					</div>
				</div>
				<div class="form-group">
					<label for="inputCompanyName"
						   class="flex align-items-center mb-1 col-form-label font-light  whitespace-nowrap"><span
						class="help-tooltip cursor-pointer"></span></label>
					<div class="mt-3">
						<svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 cursor-pointer" viewBox="0 0 20 20"
							 v-on:click="deleteItem"
							 fill="currentColor">
							<path fill-rule="evenodd"
								  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
								  clip-rule="evenodd"/>
						</svg>
					</div>
				</div>
			</div>

		</div>
		<ModalContainer v-if="showCustomerModal" width='md:w-1/3 w-11/12'
						:title="$t('customerSpecificCheckoutText')"
						@handleClose="showCustomerModal = false" :close="!true">
			<div class="container" slot="body">
				<div class="absolute inset-0 z-40" @click="openCustomersDropdown = false"
					 v-if="openCustomersDropdown"></div>
				<div class="row dm-sans-font">
					<div class="col-md-12">
						<form v-on:submit.prevent="updateCustomerSpecific">
							<div>
								<div class="form-group unset-margin">
									<label
										class="col-form-label font-bold whitespace-nowrap text-xs">{{
											$t('customerName')
										}}</label>
									<div>
										<input v-model="customerSearchName" type="text" @input="filterCustomers"
											   @focus="openCustomersDropdown = true"
											   :placeholder="$t('searchCustomers')"
											   class="flex justify-between align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-gray-500 w-full">
									</div>
									<div style='z-index:99999'
										 class="w-1/2 absolute bg-white shadow border rounded mt-1"
										 v-if="openCustomersDropdown && filteredCustomer.length > 0">
										<ul>
											<li class="py-1 pl-2 hover:bg-gray-100 cursor-pointer"
												v-for="item in filteredCustomer.slice(0, 7)" :key="item.id"
												@click="addCustomerToList(item)">{{ item.customer_name }}
											</li>
										</ul>
									</div>
								</div>
								<div class="row">
									<div class="col-md-6 mb-1 mt-3" v-for="customer in selectedCustomers"
										 :key="customer.id">
										<div class="flex justify-between bg-white border rounded-md shadow-sm p-1">
											<div>{{ customer.customer_name }}</div>
											<div>
                                                <span class="cursor-pointer"
													  @click="removeUserFromSelectedList(customer)">
                                                    <svg xmlns="http://www.w3.org/2000/svg"
														 class="h-5 w-5 bg-red-400 text-white border-2 rounded-full p-1"
														 viewBox="0 0 20 20" fill="currentColor">
                                                    <path fill-rule="evenodd"
														  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
														  clip-rule="evenodd"/>
                                                    </svg>
                                                </span>
											</div>
										</div>
									</div>
								</div>


							</div>
							<div class="form-group mt-3">
								<button :disabled="updating"
										:style="GET_CUSTOMIZATION && GET_CUSTOMIZATION.button ? 'background:' + GET_CUSTOMIZATION.button : ''"
										:class="GET_CUSTOMIZATION && GET_CUSTOMIZATION.button ? '' : 'bg-blue-900'"
										class="flex justify-center align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-white font-semibold w-full">
                                    <span v-if="updating" class="flex align-items-center space-x-3">
                                        <svg class="animate-spin mr-2 h-5 w-5 text-white"
											 xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                        <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"
												stroke-width="4"></circle>
                                        <path class="opacity-75" fill="currentColor"
											  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                        </svg>
                                        {{ $t('updating') }}
                                    </span>
									<span v-else>{{ $t('update') }}</span>
								</button>
							</div>
						</form>
					</div>
				</div>
			</div>
			<div slot="footer" class="flex space-x-5">

			</div>
		</ModalContainer>
	</div>
</template>

<script>
import {mapGetters} from "vuex"
import ModalContainer from './../../../customComponent/Modal'

export default {
	name: 'singleCheckoutCustomize',
	components: {
		ModalContainer
	},
	props: {
		item: {
			type: Object,
			required: true
		},
		configFields: {
			type: Array
		}
	},
	data: function () {
		return {
			dataObj: {
				field_title: '',
				field_map: '',
				required: false,
			},
			relevantText: 'All',
			relevantProduct: 'All',
			updating: false,
			relevantOpen: false,
			showCustomerModal: false,
			openCustomersDropdown: false,
			showCategoryModal: false,
			customerSearchName: '',
			selectedCustomers: [],
			filteredCustomer: [],
		}
	},
	computed: {
		...mapGetters({
			GET_ALL_CUSTOMERS: 'portal/GET_ALL_CUSTOMERS',
			GET_CUSTOMIZATION: 'portal/GET_CUSTOMIZATION',
		})
	},
	mounted() {
		this.dataObj = this.item
		this.selectedCustomers = this.dataObj.allowed_customers
	},
	methods: {
		filterCustomers() {
			this.$store.dispatch('portal/getCompanyEndCustomers', {page: this.$services.endpoints.COMPANY_ENDCUSTOMERS + `?limit=50&search=${this.customerSearchName}`})
				.then(res => {
					this.filteredCustomer = res.data.data
				}).catch((err) => {
				this.loading = false
				if (err.response.status === 401) {
					this.$store.commit('auth/CLEAR_AUTH_USER', null)
					window.Bus.$emit('sign-out')
				}
			})
		},
		addCustomerToList(customer) {
			// Check if customer exist already
			const filter = this.selectedCustomers.filter(item => parseInt(item.id) === parseInt(customer.id))
			if (filter.length < 1) {
				this.selectedCustomers.push(customer)
			}
			this.openCustomersDropdown = false
		},
		removeUserFromSelectedList(customer) {
			const filterRemove = this.selectedCustomers.filter(item => item.id !== customer.id)
			this.selectedCustomers = filterRemove
		},
		deleteItem: function () {
			this.$store.dispatch('portal/deleteCompanyCheckoutConfiguration', {id: this.item.id}).then(resp => {
				this.$emit('removeItem', {id: this.item.id})
			}).catch(err => {
				this.$services.helpers.notification(err.response.data.message, 'error', this)
			})
		},
		onChangeSpecific: function (select) {
			if (select === 'all') {
				this.dataObj.is_customer_specific = false
				this.relevantOpen = false
				this.updateCustomerSpecific()
			} else {
				this.dataObj.is_customer_specific = true
				this.showCustomerModal = true
				this.relevantOpen = false
			}
		},
		fireOpenRelevant: function () {
			this.relevantOpen = !this.relevantOpen

		},
		updateCustomerSpecific: function () {
			let payload = {is_customer_specific: this.dataObj.is_customer_specific}
			if (this.dataObj.is_customer_specific === false) {
				payload['allowed_customers'] = []
			} else {
				payload['allowed_customers'] = []
				this.selectedCustomers.map(item => {
					if (payload['allowed_customers'].includes(item.id) === false) {
						payload['allowed_customers'].push(item.id)
					}
				})
			}
			this.updating = true
			this.$store.dispatch('portal/updateCompanyCheckoutCustomerSpecific', {
				id: this.item.id,
				data: payload
			}).then(resp => {
				this.updating = false
				this.$services.helpers.notification(this.$t('categorySpecificUpdate'), 'success', this)
			}).catch(err => {
				this.updating = false
				if (err.response.status !== 401) {
					this.$services.helpers.notification(err.response.data.message, 'error', this)
				} else {

				}
			})
		}
	}
}
</script>

<style scoped>
.relevant {
	position: relative;
	top: 11px;
	font-weight: 700;
	font-size: 10px;
}
</style>
